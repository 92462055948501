import React, { Component } from "react";
import { UserAgentProvider, UserAgent } from "@quentin-sommer/react-useragent";
import AppStore from "../assets/img/app-store-badge.png";
import GooglePlay from "../assets/img/google-play-badge.png";

class Download extends Component {
  
  render() {  
    return (
        <UserAgentProvider ua={window.navigator.userAgent}>
            <div className="Download">
                <UserAgent computer>
                    <a href={process.env.REACT_APP_DOWNLOAD_IOS} className="cta-badge">
                    <img src={AppStore} alt="Download on the App Store" title="Download on the App Store"/>
                    </a>
                    <a href={process.env.REACT_APP_DOWNLOAD_ANDROID} className="cta-badge">
                    <img src={GooglePlay} alt="Get it on Google Play" title="Get it on Google Play"/>
                    </a>
                </UserAgent>
                <UserAgent ios>
                <a href={process.env.REACT_APP_DOWNLOAD_IOS} className="cta-badge">
                    <img src={AppStore} alt="Download on the App Store" title="Download on the App Store"/>
                </a>
                </UserAgent>
                <UserAgent android>
                <a href={process.env.REACT_APP_DOWNLOAD_ANDROID} className="cta-badge">
                    <img src={GooglePlay} alt="Get it on Google Play" title="Get it on Google Play"/>
                </a>
                </UserAgent>
            </div>
        </UserAgentProvider>
    );
  }
}

export default Download;
