import React, { Component, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import enjoy from "../assets/img/enjoy.png";
import unlimited from "../assets/img/unlimited.png";
import music from "../assets/img/music.png";
import successImg from "../assets/img/success.jpg";

class Success extends Component {
  
  render() {
    const auth = this.props.auth;
    let success;

    if (!auth.isAuthenticated()) {
      success = <Redirect to="/" />
    } else {
      success = <section className="Success">
        <div className="Success__container">
            <div className="Success__content">
                <div className="Success__text">
                    <h1 className="h1"><Translate id="title.subscription-active"/></h1>
                    <p className="p1"><Translate id="content.premium-now-active"/></p>
                    <Link to="/profile" className="Success__text__cta btn-primary"><Translate id="button.go-to-profile"/></Link>
                </div>
                <div className="Success__image">
                    <img className="Success__image--main" src={successImg} alt="Girl enjoying music"/>
                    <img className="Success__badge Success__badge-1" src={enjoy} alt="Enjoy badge"/>
                    <img className="Success__badge Success__badge-2" src={unlimited} alt="Unlimited badge"/>
                    <img className="Success__badge Success__badge-3" src={music} alt="Music badge"/>
                </div>
            </div>
        </div>
    </section>
    }

    return (
      <Fragment>
        {success}
      </Fragment>
    );
  }
}

export default withRouter(Success);
