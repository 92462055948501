import React, { Component, Fragment } from "react";
import { Translate } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import axios from "axios";

import ProfileEdit from "./profile-edit";
import ReactModal from 'react-modal';

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class Profile extends Component {
  constructor(props) {
    super(props); 
    this.state = {
        show: false,
        profileName: "",
        profileEmail: ""
    }   
  }

  componentDidMount() {
    ReactModal.setAppElement('#root');
    this.loadProfile();    
  }

  handleClose(){
    this.setState({
        show: false
    })
  }

  formatDate = date => (date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())

  loadProfile = () => {
    axios.get(`${base_services_api}/users/${this.props.auth.sub}/profile/`, { 
      headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.access_token}` }
      }
    )
    .then(resp => {
      this.setState({ 
        profileName: resp.data.FirstName,      
        profileEmail: resp.data.PrimaryEmail,
        FirstName: resp.data.FirstName,  
        LastName: resp.data.LastName,
        DisplayName: resp.data.DisplayName,
        Email: resp.data.PrimaryEmail,
        IsPublic: resp.data.IsPublic,
        Language: resp.data.Language,
        BirthYear: resp.data.BirthYear,
        Gender: resp.data.Gender,
        OptedIn: resp.data.OptedIn,
        AudioQuality: resp.data.AudioQuality,
        IsVerified: resp.data.IsVerified,
        UserCentricsControllerID: resp.data.UserCentricsControllerID
      }); 
    })
  }

  onEditClick = () => {
    this.setState({
        show: true
    })
    // this.props.history.push("/profile-edit");
  }

  render() {
    const authData = this.props.auth;
    let subscriptions;
    
    if(this.props.subscriptions.length > 0) {
        subscriptions = this.props.subscriptions.map(subscription => (

        <div className="redeem-sidebar" key={subscription.SubscriptionId}>
            <h2 className="h4">Profile details</h2>
            <div className="redeem-section">
              <div className="redeem-title"><Translate id="profile.phone-number" /></div>
              <p className="p2 right">{"+" + authData.phone.countryCode + parseInt(authData.phone.number)}</p>
            </div>

            {this.state.profileName ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.name" /></div>
                 <p className="p2 right">{this.state.profileName}</p>
              </div>
            : null }

            {this.state.profileEmail ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.emailaddress" /></div>
                <p className="p2 right">{this.state.profileEmail}</p>
              </div>
            : null}

            {/* <div className="redeem-section">
              <div className="redeem-title"><Translate id="profile.subscription" /></div>
              <Translate>
                {({ translate }) => 
                <p className="p2 right active-status">
                  {new Date(subscription.EndDate) > new Date() ? translate("profile.active") : subscription.IsAutoRenew ? translate("profile.active") : translate("profile.inactive")}
                </p>}
              </Translate>
            </div>

            {new Date(subscription.EndDate) > new Date() ?
              <Fragment>
                <div className="redeem-section">
                  <div className="redeem-title"><Translate id="profile.start-date" /></div>
                  <p className="p2 right">{this.formatDate(new Date(subscription.StartDate))}</p>
                </div>

                <div className="redeem-section">
                  <div className="redeem-title"><Translate id="profile.end-date" /></div>
                  <p className="p2 right">{this.formatDate(new Date(subscription.EndDate))}</p>
                </div>        
              </Fragment> : ''} */}

            <button className="btn-edit" onClick={this.onEditClick}><Translate id="button.edit" /></button>
               
        </div>

      ))
      
    } else {
      subscriptions = <div className="redeem-sidebar">
        {/* <div className="profile-heading"><Translate id="title.profile-details" /></div> */}
        <div className="redeem-section">
          <div className="redeem-title"><Translate id="profile.phone-number" /></div>
          <span>{"+" + authData.phone.countryCode + parseInt(authData.phone.number)}</span>
        </div>

        {this.state.profileName ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.name" /></div>
                <span>{this.state.profileName}</span>
              </div>
            : null }

            {this.state.profileEmail ? 
              <div className="redeem-section">
                <div className="redeem-title"><Translate id="profile.emailaddress" /></div>
                <span>{this.state.profileEmail}</span>
              </div>
            : null}

        <div className="redeem-section">
          <div className="redeem-title"><Translate id="profile.subscription" /></div>
          <span>
            <Translate id="profile.inactive" />
          </span>
        </div>
        <button className="btn-edit" onClick={this.onEditClick}><Translate id="button.edit" /></button>
      </div>
    }

    return (
      <Fragment>
        {subscriptions}
        <ReactModal
            isOpen={this.state.show}
            contentLabel="Model email"
            className="Account__editmodal"
        >
            <Fragment>
                <ProfileEdit {...this.state} auth={authData}/>
                <button className="ReactModal__Content__close" onClick={this.handleClose.bind(this)}>
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="#000" strokeWidth="2" d="m1.7 1.3 15 15"/>
                        <path stroke="#000" strokeWidth="2" d="m16.3 1.3-15 15"/>
                    </svg>
                </button>
            </Fragment>
        </ReactModal>
      </Fragment>
    )
  }
}

//export default Profile;
export default withRouter(Profile);