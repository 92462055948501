import React, { Component } from "react";
import taylormade from "../assets/img/taylormade.png";
// import { Translate } from "react-localize-redux";

import eventBus from "../utils/eventBus";

class HomeContentDesktop extends Component {

  render() {
    return ( 
        <section className="HomeContentDesktop">
            <div className="HomeContentDesktop__row1">
                <div className="HomeContentDesktop__rowContainer">
                    <div className="HomeContentDesktop__rowContent">
                        <h2 className="h2">
                            Millions of songs
                        </h2>
                        <p className="p2">
                            Tieme Music offers millions songs which include the latest African music and international songs. Enjoy listening to the best music without data.
                        </p>
                        <button className="btn btn-primary green" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the App</button>
                    </div>
                </div>
            </div>
            <div className="HomeContentDesktop__row2">
                <div className="HomeContentDesktop__rowContainer">
                    <div className="HomeContentDesktop__rowContent">
                        <h2 className="h2">
                            Offline mode
                        </h2>
                        <p className="p2">
                            Download all your favorites songs and play it without using data anytime, anywhere.
                        </p>
                        <button className="btn btn-primary pink" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the App</button>
                    </div>
                    <img src={taylormade} alt="Taylor-made playlist"/>
                </div>
            </div>
            <div className="HomeContentDesktop__row3">
                <div className="HomeContentDesktop__rowContainer">
                    <div className="HomeContentDesktop__rowContent">
                        <h2 className="h2">
                            Tailor-made playlists
                        </h2>
                        <p className="p2">
                            Enjoy all the playlists created just for you by our music specialists, inspired by what you love and showcasing new artists to discover.
                        </p>

                        <h2 className="h2">
                            Free and unlimited
                        </h2>
                        <p className="p2">
                            Millions of songs, playlists and albums to download for free. Enjoy the best music experience without limits.
                        </p>
                        <button className="Hero__cta btn-primary" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the App</button>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}

export default HomeContentDesktop;
