import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
// import { Translate } from "react-localize-redux";
import Hero from "../components/hero";
import HomeContentDesktop from "../components/HomeContentDesktop";
import HomeContentMobile from "../components/HomeContentMobile";
import BannerPremium from "../components/BannerPremium";

class Home extends Component {

  render() {
    
    return (
      <div id="content">
        <Hero/>
        <HomeContentDesktop />
        <HomeContentMobile />
        <BannerPremium />
        {/* <HomeContentMobile /> */}
      </div>
    );
  }
}

export default withLocalize(Home);
