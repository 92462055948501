import React, { Component } from "react";
import { Link } from "react-router-dom";
import premiumBanner from "../assets/img/premium-banner.jpg";
import premiumTitle from "../assets/img/premium-title.svg";
import premiumTitleMobile from "../assets/img/premium-title--mobile.svg";

// import { Translate } from "react-localize-redux";

class BannerPremium extends Component {

  render() {
    return ( 
        <section className="BannerPremium">
            <div className="BannerPremium__container">
                <div className="BannerPremium__content">
                    <img className="BannerPremium__title" src={premiumTitle} alt="premium banner" />
                    <img className="BannerPremium__title--mobile" src={premiumTitleMobile} alt="premium banner" />
                    <p className="BannerPremium__text p1">No more ads, unlimited offline mode, pay with MoMo</p>
                    <Link to="/premium" className="BannerPremium__cta btn-primary purple">Get premium</Link>
                    <img className="BannerPremium__image gs-anchor" src={premiumBanner} alt="premium banner" />
                </div>
            </div>
        </section>
    );
  }
}

export default BannerPremium;
