import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import Plans from "../components/plans";

class Premium extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      selectedValue: 'default'
    }
  }

  render() {
    const auth = this.props.auth;

    return (
      <div className="Plans" id="content">
        
        <section className="Plans__hero">
            <h1 className="Plans__hero__title h1">Choose your plan</h1>
            <p className="Plans__hero__text p1">Listen without limits on your phone, speaker, and other devices.</p>
        </section>

        <section className="Plans__payment" id="premium-payment">
            <Plans auth={auth}/>
        </section>  
      </div>
    );
  }
}

export default withLocalize(Premium);
