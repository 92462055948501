import React, { Component, Fragment } from "react";
import { Translate } from "react-localize-redux";

// import Login from "./login";
import ReactModal from 'react-modal';
import axios from "axios";
import axiosService from "../utils/axiosservice";

import PaystackCheckout from "../components/paystack-checkout";
import eventBus from "../utils/eventBus";

import freeImage from "../assets/img/free.svg";
import premiumImage from "../assets/img/premium_solo.svg";
import bestValue from "../assets/img/best-value.png";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;


let planMap = {};
planMap[process.env.REACT_APP_MONTHLY_TUNNED_PLAN_KEY] = process.env.REACT_APP_MONTHLY_PAYSTACK_PLAN_KEY;
planMap[process.env.REACT_APP_YEARLY_TUNNED_PLAN_KEY] = process.env.REACT_APP_YEARLY_PAYSTACK_PLAN_KEY;


class Plans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      merchantId: process.env.REACT_APP_MERCHANTID, // This is TG's Merchant ID
      plans: [],
      planId: "",
      errorMessage: "",
      isLoading: false,
      sessionId: "",
      selectedCurrency: "aud",
      selectedPaymentMethod: "card",
      subscription: "",
      activeTab: 0,
      showModal: false,
    }
  }

  componentDidMount() {
    this.auth = this.props.auth;
    ReactModal.setAppElement('#root');

    if (this.auth.isAuthenticated()) {
      this.loadPackages();
    }
    
    let selected = window.sessionStorage.getItem("selectedCurrency");
    this.setState({ selectedCurrency: selected });
    
    if (this.auth.isAuthenticated()) {
        this.loadProfile();  
    }

  }

  changeCurrency(e) {
    this.setState({ selectedCurrency: e.target.value });
    window.sessionStorage.setItem("selectedCurrency", e.target.value);
  }

  loadProfile = () => {
    axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile/`, { 
      headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
      }
    )
    .then(resp => {
      this.setState({
        subscription: resp.data.Subscriptions[0]
      })
    })
  }

  // Retrieve TG plans for a specific merchant
  loadPackages = () => {
    axiosService.get(`/merchants`)
      .then(resp => {
  
        if(this.props.auth.authData.access_token !== null) {

          axiosService.get(`/packages/allplans`, {params: { 'merchantId': `${this.state.merchantId}` }})
          .then(resp => {
            this.setState({ 
              plans: resp.data,
              isLoading: true
            })
          })
          .catch(err => {
            this.setState({ 
              isLoading: false
            })
          })
        } else {
          this.setState({ 
            isLoading: false, 
            errorMessage: "Sorry, there are no plans available. Please contact us." 
          })
        }
      
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        })
        this.props.auth.logout();
      })
      
  }

  openLogin = () => {
    eventBus.dispatch("login") 
  }
  changeActiveTab = (index) =>  {
    this.setState({ activeTab: index });
  }
  onModalClick(){
    if( this.props.auth.isAuthenticated() ){
        this.setState({
            showModal: true
        })
    }else{
        this.openLogin();
    }
  }
  handleModalClose(){
    this.setState({
        showModal: false
    })
  }
  
  render() {
    const { isLoading } = this.state;
    const auth = this.props.auth;
    let plans;
    let subscriptionInfo;
    // let loginBtn;
    let ghcPlans;

    //static plan if not logged
    if( !auth.isAuthenticated() ){
        ghcPlans = [{
            MerchantCode: null,
            Cost: 12,
            Currency: "GHC",
            period: "Month",
            periodShort: "mo",
        },{
            MerchantCode: null,
            Cost: 120,
            Currency: "GHC",
            period: "Year",
            periodShort: "yr",
        }]
    }else{
        ghcPlans = this.state.plans.filter(plan => {
            return plan.Currency === "GHC";
        });   
    }
    
    
    plans = ghcPlans.map((plan,index) => {
        const isMonth = plan.MerchantCode === process.env.REACT_APP_MONTHLY_TUNNED_PLAN_KEY;
        const period = !!plan.MerchantCode ? (isMonth ? "Month" : "Year") : plan.period;
        const periodShort = !!plan.MerchantCode ? (isMonth ? "Month" : "Year") : plan.periodShort;
        return   this.state.activeTab === index && <Fragment key={plan.MerchantCode}> 
            <div className={`Plans__payment__item ${auth.isAuthenticated() ? "hide":""}`}>
                <div className="Plans__payment__item__top">
                    <img className="Plans__payment__item__title" src={freeImage} alt="free logotype"/>
                </div>
                <div className="Plans__payment__item__bottom">
                    <p className="p1">
                        Unlimited music <br/>
                        offline <br/>
                        with ads
                    </p>
                    <span className="Plans__payment__item__price"><strong>0 GHS</strong></span>
                    <a href="#test" className="btn-primary yellow" >Start for free</a>
                </div>
            </div>
            <div className="Plans__payment__item big">
                <div className="Plans__payment__item__top">
                    <img className="Plans__payment__item__title" src={premiumImage} alt="Free logotype"/>
                    <img className="Plans__payment__item__badge" src={bestValue} alt="Best value logotype"/>
                </div>
                <div className="Plans__payment__item__bottom">
                    <p className="p1">
                        Unlimited music <br/>
                        offline <br/>
                        <strong>ad-free</strong>
                    </p>
                    <span className="Plans__payment__item__price"><strong>{plan.Cost} {plan.Currency}</strong> / {periodShort}</span>
                    {this.state.subscription ? 
                        "" : 
                        <button className="btn-primary purple" onClick={this.onModalClick.bind(this)}>Get premium</button>
                    }
                    <ReactModal
                        isOpen={this.state.showModal}
                        contentLabel="Modal payment"
                        className="Plans__modalpayments"
                    >
                        <h2 className="h2">Premium solo {this.state.activeTab === 0 ? 'monthly':'yearly'}</h2>
                        <p className="p2">How would you like to pay?</p>
                        <div className="Plans__modalpayments__solution">
                            <p className="Plans__modalpayments__solution__title">Prepay</p>
                            <p className="p2">One-time payment<br/>Top up when you want.<br/>Does not auto-renew.</p>
                            <span className="Plans__modalpayments__solution__price"><strong>{plan.Cost} {plan.Currency}</strong> / {period}</span>

                            <PaystackCheckout title="Pay with MoMo" channel="mobile_money" auth={auth} tunedPlanId={plan.MerchantCode} paystaskPlanId={planMap[plan.MerchantCode]} amount={ +plan.Cost * 100} />
                        </div>
                        <div className="Plans__modalpayments__solution">
                            <p className="Plans__modalpayments__solution__title">Subscribe</p>
                            <p className="p2">Auto-renews {period}ly<br/> cancel anytime<br/>Yearly subscription.</p>
                            <span className="Plans__modalpayments__solution__price"><strong>{plan.Cost} {plan.Currency}</strong> / {period}</span>
                            <PaystackCheckout title="Pay with card" channel="bank" auth={auth} tunedPlanId={plan.MerchantCode} paystaskPlanId={planMap[plan.MerchantCode]} />
                        </div>
                        <button className="ReactModal__Content__close" onClick={this.handleModalClose.bind(this)}>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path stroke="#000" strokeWidth="2" d="m1.7 1.3 15 15"/>
                                <path stroke="#000" strokeWidth="2" d="m16.3 1.3-15 15"/>
                            </svg>
                        </button>
                    </ReactModal>
                </div>
            </div>
        </Fragment>
    })

    subscriptionInfo = <Fragment>
        {this.state.subscription ? 
            <div className="callout">You already have an active subscription</div> : ""
        }
    </Fragment>

    const tabSwitcher = <div className="Plans__tabswitcher">
        <button onClick={()=>{this.changeActiveTab(0)}} className={this.state.activeTab === 0 ? 'active':''}>Monthly</button>
        <button onClick={()=>{this.changeActiveTab(1)}} className={this.state.activeTab === 1 ? 'active':''}>Yearly</button>
    </div>


    if (!isLoading && auth.isAuthenticated()) {
      return <div className="Plans__payment__loader">
        <div className="container">
          <div className="spinner">
            <div></div><div></div><div></div><div></div>
          </div>
        </div>
        <p><Translate id="content.loading-plans" /></p>
      </div>;
    } else {
      return (
        <Fragment>
            {subscriptionInfo}
            { tabSwitcher }
            <div className="Plans__payment__items">
                {plans}
            </div>
            { 
                this.state.errorMessage !== '' &&
                <div>
                    {this.state.errorMessage}
                </div>
            }
        </Fragment>
      )
    }
  }
}

export default Plans;