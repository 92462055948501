import React, { Component } from 'react';
import { Redirect } from "react-router-dom";

import { Translate } from "react-localize-redux";
import axios from "axios";

import PaystackPop from '@paystack/inline-js'
import ReactModal from 'react-modal';

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class PaystackCheckout extends Component {
    constructor(props){
        super(props); 
        this.state = {
            show: false,
            redirect: false,
            Email: ''
        }
    }

    componentDidMount() {
        ReactModal.setAppElement('#root');
        this.loadProfile();
    }

    loadProfile = () => {
        axios.get(`${base_services_api}/users/${this.props.auth.authData.sub}/profile/`, { 
          headers: { 'StoreId': storeId, 'Authorization': `Bearer ${this.props.auth.authData.access_token}` }
          }
        )
        .then(resp => {
          this.setState({ 
            Email: resp.data.PrimaryEmail
          }); 
        })
        .catch(err => {
          console.log("error encountered while loading profile.");
          console.log(err);      
        })
      }
    
    changeHandler = e => {
        this.setState({ [e.target.name]: e.target.value })
    }
    
    submitHandler = e => {
        e.preventDefault();
        axios.patch(`${base_services_api}/users/me/details`,this.state,
            { 
            headers: { 
                'StoreId': storeId, 
                'Authorization': `Bearer ${this.props.auth.authData.access_token}`
            }        
            }
        )  
        .then(resp => {
            this.handleClose();
            this.createTransaction();
        })
        .catch(err => {
            this.handleClose();
            console.log("error encountered while saving profile.");
            console.log(err);
        }) 
    }

    handleClose(){
        this.setState({
            show: false
        })
    }

    showPopupEmail(){
        this.setState({
            show: true
        })
    }

    onModalClick(){
        if( !!this.state.Email ){
            this.createTransaction();
        }else{
            this.showPopupEmail();
        }
    }

    createTransaction(){
        const paystack = new PaystackPop();
        let model = {
            key: process.env.REACT_APP_PAYSTACK_PUBLIC,
            email: this.state.Email,
            currency: "GHS",
            metadata: {
                tunedPlanId: this.props.tunedPlanId,
                paystaskPlanId: this.props.paystaskPlanId,
                msisdn: this.props.auth.authData.phone.countryCode + parseInt(this.props.auth.authData.phone.number),
                phone: "+" + this.props.auth.authData.phone.countryCode + parseInt(this.props.auth.authData.phone.number),
            }
        };

        if( this.props.channel === 'mobile_money'){
            model.amount = this.props.amount;
            model.channels = ['mobile_money'];
        }else{
            model.plan = this.props.paystaskPlanId;
            model.channels = ['bank', 'bank_transfer'];
        }
        
        paystack.newTransaction({
            ...model,
            onSuccess: () => {
                this.setState({ 
                    redirect: true
                }); 
            },
            onCancel: () => {}
        });
    }

    render() {
        const { Email } = this.state;
        return (
            <>
            { this.state.redirect && <Redirect to="/success" /> } 
            <button className="btn-primary" type="button" onClick={this.onModalClick.bind(this)}>
                {this.props.title}
            </button>
            <ReactModal
                isOpen={this.state.show}
                contentLabel="Model email"
                className="Plans__modalemail"
            >
                <p className="modal-title">Email is needed to start transaction</p>
                <form onSubmit={this.submitHandler}>
                    <div className="form-group">
                        <label><Translate id="content.email-address" /></label>
                        <Translate>
                        {({ translate }) => 
                        <input type="email" className="form-input" name="Email" value={Email} onChange={this.changeHandler} placeholder={translate("content.enter-email")} maxLength="50"/>}
                        </Translate>
                    </div>
                    <button className="btn-primary" type='submit' ><Translate id="button.submit" /></button>
                </form>
                <button className="ReactModal__Content__close" onClick={this.handleClose.bind(this)}>
                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="#000" stroke-width="2" d="m1.7 1.3 15 15"/>
                        <path stroke="#000" stroke-width="2" d="m16.3 1.3-15 15"/>
                    </svg>
                </button>
            </ReactModal>
            </>
        )
    }
}

export default PaystackCheckout;