import React, { Component } from "react";
import beHeard from "../assets/img/offline.png";
// import { Translate } from "react-localize-redux";
import MaskImage from "../components/MaskImage";
import Download from "../components/download";

import eventBus from "../utils/eventBus";

class Hero extends Component {

  render() {
    return ( 
        <section className="Hero">
            <div className="Hero__container">
                <div className="Hero__content__left">
                    <h1 className="Hero__title h1">Listen to your music<img className="Hero__badge" src={beHeard} alt="be heard"/></h1>
                    <p className="Hero__text p1">Stream and download your favorites albums and playlists for free without data !</p>
                    <button className="Hero__cta btn-primary" onClick={()=>{ eventBus.dispatch('comingsoon') }}>
                        Download the App
                    </button>
                    <Download />
                </div>
                <div className="Hero__content__right">
                    <MaskImage />
                </div>
            </div>
        </section>
    );
  }
}

export default Hero;
