import { Component } from "react";
// import ReactModal from 'react-modal';

import eventBus from "../utils/eventBus";

class ComingSoon extends Component {
    constructor(props){
        super(props); 
        this.state = {
            showModal: false,
        }
    }

    isApple() {
        let plat = 'unknown'
        // 2022 way of detecting. Note : this userAgentData feature is available only in secure contexts (HTTPS)
        if (typeof navigator.userAgentData !== 'undefined' && navigator.userAgentData != null) {
            plat =  navigator.userAgentData.platform;
        }
        // Deprecated but still works for most of the browser
        if (typeof navigator.platform !== 'undefined') {
            if (typeof navigator.userAgent !== 'undefined' && /android/.test(navigator.userAgent.toLowerCase())) {
                // android device’s navigator.platform is often set as 'linux', so let’s use userAgent for them
                plat =  'android';
            }
            plat =  navigator.platform;
        }

        let platform = plat.toLowerCase();

        let isOSX = /mac/.test(platform); // Mac desktop
        let isIOS = ['iphone', 'ipad', 'ipod'].includes(platform); // Mac iOs
        return isOSX || isIOS;
        
    }

    componentDidMount() {
        // ReactModal.setAppElement('#root');
        eventBus.on("comingsoon", (data) =>
        // this.setState({ showModal: true })
            window.location.href = this.isApple() ? process.env.REACT_APP_DOWNLOAD_IOS : process.env.REACT_APP_DOWNLOAD_ANDROID
        );
    }

    handleClose(){
        // this.setState({
        //     showModal: false
        // })
    }

    render() {
        return null
        // return ( 
        //     <Fragment>
        //         <ReactModal
        //             isOpen={this.state.showModal}
        //             contentLabel="Modal coming soon"
        //             className="modalcomingsoon"
        //         >
        //             <p className="h2">Coming soon !</p>
        //             <button className="ReactModal__Content__close" onClick={this.handleClose.bind(this)}>
        //                 <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        //                     <path stroke="#000" stroke-width="2" d="m1.7 1.3 15 15"/>
        //                     <path stroke="#000" stroke-width="2" d="m16.3 1.3-15 15"/>
        //                 </svg>
        //             </button>
        //         </ReactModal>
        //     </Fragment>
        // );
    }
}

export default ComingSoon;
