import React, { Component } from "react";
import imgProduct1 from "../assets/img/product-1.png";
import imgProduct2 from "../assets/img/product-2.png";
import imgProduct3 from "../assets/img/product-3.png";
// import { Translate } from "react-localize-redux";

import { gsap, Power4 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class MaskImage extends Component {

    componentDidMount(){
        this.initTimeline();
        this.initMaskClipper();
    }
    componentWillUnmount() { 
        this.tl.kill()
    }
    

    initTimeline(){
        this.tl = gsap.timeline({ease: Power4.easeInOut, scrollTrigger: {
            trigger: ".MaskImage",
            start: 0,
            end: "top bottom",
            endTrigger: '.gs-anchor',
            scrub: 0.5,
            pin: true,
            pinSpacing: false,
            onRefresh: ()=>{this.onResize()}
        }});
        
        this.tl.to(".MaskImage1,.MaskImage2,.MaskImage3", {rotate: 8.5});
        this.tl.to(".MaskImage1,.MaskImage2,.MaskImage3", {rotate: -8.5});
        this.tl.to(".MaskImage1,.MaskImage2,.MaskImage3", {rotate: 0});  
    }

    onResize(){
        if(!!this.clipped){
            this.clipped.onResize();
        }
    }

    initMaskClipper(){
        this.clipped = new Clipper({ elms: [
            {
                first: true,
                el: document.querySelector('.MaskImage__content1'), 
                target: document.querySelector('.HomeContentDesktop__row1')
            }
            ,
            {
                el: document.querySelector('.MaskImage__content2'), 
                target: document.querySelector('.HomeContentDesktop__row2')
            },
            {
                last: true,
                el: document.querySelector('.MaskImage__content3'), 
                target: document.querySelector('.HomeContentDesktop__row3')
            }
        ]});
    }

    render() {
        return ( 
            <div className="MaskImage">
                <div className="MaskImage__content MaskImage__content1">
                    <img src={imgProduct1} className="MaskImage1" alt="Product screenshot"/>
                </div>
                <div className="MaskImage__content MaskImage__content2">
                    <img src={imgProduct2} className="MaskImage2" alt="Product screenshot"/>
                </div>
                <div className="MaskImage__content MaskImage__content3">
                    <img src={imgProduct3} className="MaskImage3" alt="Product screenshot"/>
                </div>
            </div>
        );
    }
}

export default MaskImage;

class Clipper{
	constructor({elms}){
        this.elms = elms;

        this.setIntersectionObserver();
        this.computeWindowState();
        this.computeElms();
        this.update();

        this._onScroll = this.onScroll.bind(this);
        window.addEventListener('scroll', this._onScroll);
    }

    setIntersectionObserver(){

        function handleIntersection(entries) {
            entries.forEach((entry) => {
                entry.target.active = entry.isIntersecting;
            });
        }

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0,
        });

        this.elms.forEach(elm => observer.observe(elm.target, {pouet: true}));
    }
	
	applyClip(item){
        if(!item.target.active) return;
        let progressTop = -100;
        let progressBottom = 500;
        if( !item.first ){
            progressTop =  (item.target.top - this.scroll) - this.maskBounding.top;
        }
        if( !item.last ){
            progressBottom =  (item.target.bottom - this.scroll) - this.maskBounding.bottom;
        }
		// item.el.style.clipPath = `inset(${progressTop}px -200px ${(this.maskBounding.height - progressBottom) - this.maskBounding.height}px -200px)`
		item.el.style.clip = `rect(${progressTop}px, 450px, ${this.maskBounding.height + progressBottom}px, -100px)`
	}
      

	computeElms(){
        this.scroll = window.scrollY;
        //Issue when resize and not pinned
		this.maskBounding = document.querySelector('.Hero__content__right').getBoundingClientRect();
        this.maskBounding = {top: this.maskBounding.top + this.scroll, bottom: this.maskBounding.bottom + this.scroll, height: this.maskBounding.height}

        // this.maskBounding={top: dist, bottom: dist+660, height: 660}
		this.elms.map((item)=>{
			const bounding = item.target.getBoundingClientRect();
			item.target.top = bounding.top + window.scrollY;
			item.target.bottom = bounding.bottom  + window.scrollY;
            return item;
		})
	}

	computeWindowState(){
		this.wH = window.innerHeight;
	}

	onResize(event){
		this.computeWindowState();
		this.computeElms();
	}

	update(){
        this.scroll = (window.scrollY || window.pageYOffset || document.documentElement.scrollTop || 0);
        for (let index = 0; index < this.elms.length; index++) {
            this.applyClip(this.elms[index]);
        }
	}

	onScroll(event){
		this.update();
	}
}
