import React, { Component } from "react";
// import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../assets/img/logo.svg";
import facebook from "../assets/img/socials/facebook.svg";
import twitter from "../assets/img/socials/twitter.svg";
import instagram from "../assets/img/socials/instagram.svg";
import youtube from "../assets/img/socials/youtube.svg";

class Footer extends Component {

  getYear() {
    return new Date().getFullYear();
  }

  render() {
    return (
        <footer className="Footer">
            <div className="Footer__container">
                <Logo className="Footer__logo" />
                <ul className="Footer__links">
                    <li><a rel="noreferrer" href="mailto:support@tiememusic.com" target="_blank" className="p2">Contact</a></li>
                    <li><a rel="noreferrer" href="https://distribution.tiememusic.com/" target="_blank" className="p2">Tieme Distribution</a></li>
                    <li><a rel="noreferrer" href="https://distro.tiememusic.com/terms_and_conditions.php" target="_blank" className="p2">Legal terms</a></li>
                    <li><a rel="noreferrer" href="https://distro.tiememusic.com/privacy.php" target="_blank" className="p2">Privacy policy</a></li>
                </ul>
                <ul className="Footer__socials">
                    <li><a rel="noreferrer" href="https://www.facebook.com/tiememusic" target="_blank"><img src={facebook} alt="facebook logo"/></a></li>
                    <li><a rel="noreferrer" href="/" target="_blank"><img src={twitter} alt="twitter logo"/></a></li>
                    <li><a rel="noreferrer" href="https://www.instagram.com/tiememusic/" target="_blank"><img src={instagram} alt="instagram logo"/></a></li>
                    <li><a rel="noreferrer" href="/" target="_blank"><img src={youtube} alt="youtube logo"/></a></li>
                </ul>
                <div className="Footer__copyright p2">
                    Copyright {this.getYear()} Tieme Music. All rights reserved.
                </div>
            </div>
        </footer>
    );
  }
}

export default Footer;
