import React, { Component } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import globalTranslations from "../translations/global.json";
import profileSvg from "../assets/img/profileSvg.svg";
import Login from "../components/login";
// import MobileNav from "../components/nav";
// import LanguageToggle from "../components/languageselect"; 

import { ReactComponent as Logo } from "../assets/img/logo.svg";


class Header extends Component {

  constructor(props) {
    super(props);

    const languages = [
      { name: "English", code: "en" },
      { name: "Italian", code: "it" }
    ];
    
    const defaultLanguage = window.localStorage.getItem("languageCode") || languages[0].code;
    
    this.props.initialize({
      languages,
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        defaultLanguage
      }

    }); 
  }

  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage.code;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage.code;

    const hasLanguageChanged = prevLangCode !== curLangCode;
    
    if (hasLanguageChanged) {
      window.localStorage.setItem("languageCode", curLangCode);
    }

  }
  
  render() {
    this.auth = this.props.auth;
    let premiumBtn = (
        <Link to="/premium">pricing</Link>
    );
    let profilBtn = (
        <Link to="/profile">
            <img className="Header__profile" src={profileSvg} alt="my account"/>
        </Link>
    )
    let loginBtn = (
        <Login auth={this.props.auth} />
    )
    
    return ( 
      <header className="Header">
        {/* <div className="language">
          <div className="container">
            <LanguageToggle />
          </div>
        </div> */}

        <div className="Header__container">
            <div className="Header__content">
            <a href="/" className="Header__logo"><Logo fill="#FC5F61" alt="Website Name" /></a>
            {/* <nav className="nav-desktop">
                <ul>
                    <li><Link to="/"><Translate id="nav.home" /></Link></li>
                    <li><Link to="/premium"><Translate id="nav.premium" /></Link></li> 
                </ul>
            </nav> */}

            <div className="Header__nav"> 
                {premiumBtn}
                {this.props.auth.isAuthenticated() &&  profilBtn}
                {!this.props.auth.isAuthenticated() && loginBtn}
            </div>
                
            {/* <MobileNav pageWrapId={"page-content"} outerContainerId={"App"} /> */}
    
            </div>
        </div>
      </header> 
    )
  }
}

export default withLocalize(Header);
