import React, { Component } from "react";

import imgProduct1 from "../assets/img/product-1.png";
import imgProduct2 from "../assets/img/product-2.png";
import imgProduct3 from "../assets/img/product-3.png";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import eventBus from "../utils/eventBus";


class HomeContentMobile extends Component {
    render() {
        return ( 
            <section className="HomeContentMobile">
                <Swiper className={'HomeContentMobile__slider'}
                    pagination={true} 
                    // centeredSlides={true}
                    // setWrapperSize={true}
                    cssMode={true}
                    modules={[Pagination]}
                    spaceBetween={0}
                    breakpoints= {{
                        400: {
                            slidesPerView: 1.5
                        },
                        500: {
                            slidesPerView: 1.7
                        },
                        600: {
                            slidesPerView: 1.9
                        },
                        800: {
                            slidesPerView: 2.2
                        },

                        1000: {
                            slidesPerView: 2.5
                        }
                    }}                    
                    slidesPerView={1.2}>
                    <SwiperSlide>
                        <div className="HomeContentMobile__item">
                            <img src={imgProduct1} className="HomeContentMobile__item__image" alt="product screenshot 1"/>
                            <div className="HomeContentMobile__item__description">
                                <div>
                                    <h2 className="h2">
                                        Millions of songs
                                    </h2>
                                    <p className="p2">
                                    Tieme Music offers millions songs which include the latest African music and international songs. Enjoy listening to the best music without data.
                                    </p>
                                </div>
                                <button className="btn btn-primary green" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the app</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    
                    <SwiperSlide>
                        <div className="HomeContentMobile__item purple">
                            <img src={imgProduct2} className="HomeContentMobile__item__image" alt="product screenshot 2"/>
                            <div className="HomeContentMobile__item__description">
                                <div>
                                    <h2 className="h2">
                                        Offline mode
                                    </h2>
                                    <p className="p2">
                                        Download all your favorites songs and play it without using data anytime, anywhere.
                                    </p>
                                </div>
                                <button className="btn btn-primary pink" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the App</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="HomeContentMobile__item">
                            <img src={imgProduct3} className="HomeContentMobile__item__image" alt="product screenshot 3"/>
                            <div className="HomeContentMobile__item__description">
                                <div>
                                    <h2 className="h2">
                                        Tailor-made playlists
                                    </h2>
                                    <p className="p2">
                                    Enjoy all the playlists created just for you by our music specialists, inspired by what you love and showcasing new artists to discover.
                                    </p>
                                </div>
                                <button className="btn btn-primary green" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the App</button>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="HomeContentMobile__item">
                            <img src={imgProduct1} className="HomeContentMobile__item__image" alt="product screenshot 4"/>
                            <div className="HomeContentMobile__item__description">
                                <div>
                                    <h2 className="h2">
                                        Free and unlimited
                                    </h2>
                                    <p className="p2">
                                    Millions of songs, playlists and albums to download for free. Enjoy the best music experience without limits.
                                    </p>
                                </div>
                                <button className="btn btn-primary green" onClick={()=>{ eventBus.dispatch('comingsoon') }}>Download the App</button>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </section>
        );
    }
}

export default HomeContentMobile;
