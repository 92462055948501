import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
import axios from "axios";

const storeId = process.env.REACT_APP_STOREID;
const base_services_api = process.env.REACT_APP_SERVICES_API_BASE;

class ProfileEdit extends Component{
  constructor(props) {
    super(props); 
    this.state = {
      FirstName: this.props.FirstName,
      LastName: this.props.LastName,
      DisplayName: this.props.DisplayName,
      Email: this.props.Email,
    //   IsPublic: false,
    //   Language: '',
    //   BirthYear: 0,
    //   Gender: '',
    //   OptedIn: false,
    //   Iso2CountryCode: '',
    //   TestUser: false,
    //   Tags: '',
    //   AudioQuality: '',
    //   IsVerified: false,
    //   UserCentricsControllerID: ''
    }   
  }


  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitHandler = e => {
    e.preventDefault();
    axios.patch(`${base_services_api}/users/me/details`,this.state,
     { 
       headers: { 
         'StoreId': storeId, 
         'Authorization': `Bearer ${this.props.auth.access_token}`
       }        
     }
   )  
   .then(resp => {
    // this.props.history.push("/profile");
    window.location.reload(false);
   })
   .catch(err => {
      console.log("error encountered while saving profile.");
      console.log(err);
    }) 
  }

  render() {
    const { FirstName, LastName, Email } = this.state;
    return (
        <section class="Account__popin">
            <div className="Account__popin__edit">
              <span className="h2">Profile details</span>
              <form onSubmit={this.submitHandler}>
                <div className="form-group">
                    <label><Translate id="content.first-name" /></label>
                    <Translate>
                    {({ translate }) => 
                    <input type="text" className="form-input" name="FirstName" value={FirstName} onChange={this.changeHandler} placeholder={translate("content.enter-firstname")} maxLength="50"/>}
                    </Translate>
                </div>
                <div className="form-group">
                    <label><Translate id="content.last-name" /></label>
                    <Translate>
                    {({ translate }) => 
                    <input type="text" className="form-input" name="LastName" value={LastName} onChange={this.changeHandler} placeholder={translate("content.enter-lastname")} maxLength="50"/>}
                    </Translate>
                </div>
                <div className="form-group">
                    <label><Translate id="content.email-address" /></label>
                    <Translate>
                    {({ translate }) => 
                    <input type="text" className="form-input" name="Email" value={Email} onChange={this.changeHandler} placeholder={translate("content.enter-email")} maxLength="50"/>}
                    </Translate>
                </div>                       
                <button className="btn-primary" type='submit' >Save</button>
              </form>   
            </div>
        </section>       
   )
 }
}

export default withRouter(ProfileEdit);
