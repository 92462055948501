import React, { Component } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Countdown from "react-countdown-now";
import getCountries from "./countries";
import { Translate } from "react-localize-redux";

// Firebase Phone Auth Config -
// Create your project within Firebase and update the below
const firebaseConfig = {
  apiKey: "AIzaSyDkdyTAfxCcB0sy2MbuRh89pwpje54l8kc",
  authDomain: "tieme-music.firebaseapp.com",
  projectId: "tieme-music",
  storageBucket: "tieme-music.appspot.com",
  messagingSenderId: "478850331854",
  appId: "1:478850331854:web:9e7b3db1e2e9e9a59f4c9f",
  measurementId: "G-X3LHH6Z1DQ"
};

class PhoneAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendOTP: false, 
      phone_number: "",
      country_code: "+233", //Change to country default caller code 
      complete_phone: "",
      currentUser: null,
      otp: "",
      countdown: 20,
      isButtonDisabled: true,
      showModal: true,
      show: false,
      isLoading: false
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }

    this.showModal = this.showModal.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

   
    this.doSomething = this.doSomething.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.hide = this.hide.bind(this);

  }
   
  doSomething(e) {
    e.preventDefault();
    console.log(e.target.innerHTML);
  }

  toggleShow() {
    this.setState({ show: !this.state.show })
  }

  hide(e){
    if(e && e.relatedTarget){
      e.relatedTarget.click();
    }
    this.setState({ show: false });
  }

  showModal() {
    this.setState({ showModal: true });
  }

  countdown = () => {
    for (let i = 10; i => 0; i--) {
      setTimeout(() => {
        this.setState({ countdown: i });
      }, i * 3000);
    }
  };

  componentDidMount() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: response => {
          console.log("Captcha solved")
        },

      }
    );
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }

  handlePhoneChange = event => {
    this.setState({ phone_number: event.target.value });
  };

  handleCountryChange = event => {
    this.setState({ country_code: event.target.value });
  };

  handleOTPChange = event => {
    this.setState({ otp: event.target.value });
  };

  handleLogin = () => {

    const { country_code, phone_number } = this.state;
    var complete_phone = (country_code + "" + parseInt(phone_number) + "");
    
    this.setState({
      complete_phone
    })

    var appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(complete_phone, appVerifier)
      .then(confirmationResult => {
        this.setState({ sendOTP: true });
        window.confirmationResult = confirmationResult;
      })
      .catch(err => {
        this.setState({ hasError: err.message });
      });
  };

  getIdToken = (result) => {
    var that = this;
    var response = result;
    firebase
      .auth()
      .currentUser
      .getIdToken(true)
      .then(function (idToken) {
        response.data = {
          phone: {
            number: that.state.phone_number,
            countryCode: that.state.country_code.substring(1)
          },
          token: idToken,
        }
        that.props.loginResponse(response);
      }).catch(function (error) {
        console.log(error);
      });
  }

  handleOTPCheck = () => {
    var that = this;

    this.setState({ isLoading: true });

    window.confirmationResult
      .confirm(this.state.otp)
      .then(function (result) {
        result.status = 'PARTIALLY_AUTHENTICATED';
        that.getIdToken(result);
      })
      .catch(err => {
        console.log("error")
        this.setState({ hasError: err.message });
      });
  };

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    if (this.state.sendOTP === false && this.state.showModal === true) {
      return (
        <div className="phonelogin-container">
          <div className="mdl-card mdl-shadow--2dp phonelogin-id-page-phone-sign-in-start">
            <form onSubmit={this.handleSubmit}>
              <div className="phonelogin-card-header">
                <h1 className="phonelogin-title"><Translate id="auth.enter-phone-number"/></h1>
              </div>

              <div className="phonelogin-card-content">
                <div className="phonelogin-relative-wrapper">
                  <div className="phonelogin-phone-number">

                    <div
                      className="phonelogin-id-country-selector phonelogin-country-selector mdl-button mdl-js-button"
                      onChange={this.handleCountryChange}
                    >
                      <button 
                        type="button"
                        onClick={this.toggleShow}
                        onBlur={this.hide}
                      >
                        &lrm;{this.state.country_code}
                      </button>

                      {
                        this.state.show &&
                        (
                          <ul className="phonelogin-country-dropdown">
                            {getCountries().map(country => {
                              return (
                                <li key={country.name}>
                                  <button onClick={() => { this.setState({ country_code: country.dial_code }) }}>
                                    <span className={"phonelogin-flag phonelogin-country-selector-flag phonelogin-id-country-selector-flag phonelogin-flag-" + country.code}/>
                                    <span>&lrm;{country.name + " " + country.dial_code}</span>
                                  </button>
                                </li>
                              )
                            })}
                          </ul>
                        )
                      }

                    </div>
                      
                    <div
                      className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label phonelogin-textfield phonelogin-phone-input-wrapper is-upgraded"
                    >
                      <Translate>
                        {({ translate }) => 
                          <input
                            value={this.state.phone_number}
                            onChange={this.handlePhoneChange}
                            placeholder={translate("auth.phone-number")}
                            type="tel"
                            name="phoneNumber"
                            className="mdl-textfield__input phonelogin-input phonelogin-id-phone-number"
                          />}
                      </Translate>
                    </div>
                  </div>
                  <div className="phonelogin-error-wrapper">
                    <p className="phonelogin-error phonelogin-text-input-error phonelogin-hidden phonelogin-phone-number-error phonelogin-id-phone-number-error" />
                  </div>
                  <div id="recaptcha-container" />
                  <div className="phonelogin-recaptcha-wrapper">
                    <div className="phonelogin-recaptcha-container" />

                    <div className="phonelogin-error-wrapper phonelogin-recaptcha-error-wrapper">
                      <p className="phonelogin-error phonelogin-hidden phonelogin-id-recaptcha-error" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="phonelogin-card-actions">
                <div className="phonelogin-form-actions">
                  <button
                    onClick={() => { this.props.closeModal() }}
                    className="phonelogin-id-secondary-link phonelogin-button mdl-button mdl-js-button mdl-button--primary"
                  >
                    <Translate id="button.cancel"/>
                  </button>
                  <button
                    onClick={() => { this.handleLogin() }}
                    className="phonelogin-id-submit phonelogin-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored"
                  >
                    <Translate id="button.verify"/>
                  </button>
                </div>
              </div>

              {this.state.hasError ? <p className="phonelogin-card-actions phonelogin-error phonelogin-id-recaptcha-error">{this.state.hasError}</p> : ""}

              <div className="phonelogin-card-footer">
                <p className="phonelogin-tos phonelogin-phone-sms-notice">
                <Translate id="auth.phone-auth-terms"/>
                </p>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
      const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state

          return (
            <a
              href="#!"
              onClick={this.handleLogin}
              className="phonelogin-id-resend-link phonelogin-link"
            >
              <Translate id="button.resend"/>
            </a>
          );
        } else {
          // Render a countdown
          return (
            <span className="phonelogin-id-resend-countdown">
              <p><Translate id="auth.resend-code-in"/> {seconds}</p>
            </span>
          );
        }
      };

      if (this.state.showModal === true) {
        return (
          <div className="phonelogin-container">
            <div className="mdl-card mdl-shadow--2dp phonelogin-id-page-phone-sign-in-finish">
              <form onSubmit={this.handleSubmit}>
                <div className="phonelogin-card-header">
                  <h1 className="phonelogin-title"><Translate id="auth.verify-phone-number"/></h1>
                </div>
                <div className="phonelogin-card-content">
                  <p className="phonelogin-text">
                  <Translate id="auth.enter-otp"/>
                  </p>
                  <div
                    className="phonelogin-textfield mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-upgraded"
                  >
                    <input
                      value={this.state.otp}
                      onChange={this.handleOTPChange}
                      type="number"
                      name="phoneConfirmationCode"
                      className="mdl-textfield__input phonelogin-input phonelogin-id-phone-confirmation-code"
                    />
                  </div>
                  <div className="phonelogin-error-wrapper">
                    {this.state.hasError ? <p className="phonelogin-error phonelogin-text-input-error phonelogin-hidden phonelogin-id-phone-confirmation-code-error">{this.state.hasError}</p> : ""}
                  </div>
                </div>
                <div className="phonelogin-card-actions">
                  <div className="phonelogin-form-actions">
                    <button
                      onClick={() => {
                        this.setState({ sendOTP: false })
                        this.props.closeModal()
                      }}
                      className="phonelogin-id-secondary-link phonelogin-button mdl-button mdl-js-button mdl-button--primary"
                    >
                      <Translate id="button.cancel"/>
                    </button>
                   
                    {this.state.isLoading ? 
                      <div className="phonelogin-button">
                        <div className="spinner">
                          <div></div><div></div><div></div><div></div>
                        </div>
                      </div>
                    :  <><button
                      onClick={this.handleOTPCheck.bind(this)}
                      type="submit"
                      className="phonelogin-id-submit phonelogin-button mdl-button mdl-js-button mdl-button--raised mdl-button--colored"><Translate id="button.continue"/></button>

                      <div className="phonelogin-resend-container">
                        <Countdown date={Date.now() + 20000} renderer={renderer} />
                      </div></>
                    }
                    
                  </div>
                </div>
              </form>
              
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
  }
}

export default PhoneAuth;