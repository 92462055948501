import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";

class Failed extends Component {
  
  render() {
    return (
      <div className="page-content bg-gradient" id="content">
        <section>
            <div className="u-text-center">
                <h1><Translate id="title.payment-cancelled" /></h1>
                <p className="u-text-white"><Translate id="content.issues-with-payment" /></p>
            </div>
        </section>
      </div>
    )
  }
}

export default withRouter(Failed);
