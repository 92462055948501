import React from "react";
import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Translate } from "react-localize-redux";
//import PhoneButton from "../components/phonebutton";
import Login from "../components/login";
import Download from "../components/download";
import axiosService from "../utils/axiosservice";

function AcceptInvite(props){
  //const params = useParams();
  const [acceptResult, setacceptResult] = useState("");
  const queryParams = new URLSearchParams(window.location.search)
  const paramCode = queryParams.get("code");
  
  function onAcceptClick(){
    axiosService.post(`/subscription/invitations/${paramCode}`,null)  
   .then(resp => {
      sessionStorage.removeItem('sharedCode');
      setacceptResult("You are now part of the family plan");          
   })
   .catch(err => {
      const status = err;

      switch (status) {
        case "400":
          setacceptResult("Sorry, you are already on an active subscription. Cancel your subscription to join this plan.");
          break;
        default: setacceptResult("Something went wrong. This invite may have already been used or expired.");
      }
    }) 
          
 }

  let btnAccept;
  let inviteHeader;
  if(props.auth.isAuthenticated())
  {
     inviteHeader = <div className="section-heading text-center"><Translate id="content.join-family-plan" /></div>
     btnAccept = <button className="btn-primary" onClick={onAcceptClick}><Translate id="button.accept-invite" /></button>
  }
  else
  {
    sessionStorage.setItem('sharedCode', paramCode);
    inviteHeader = <p className="text-center"><Translate id="content.login-or-register-invite" /></p>
    btnAccept = <Login auth={props.auth} btnClass="nav-btn" />
  }

  return (
    <div className="page-content" id="content">
       
        <section className="premium-payment" id="premium-payment">
            {inviteHeader}
            {btnAccept}
            <p>{acceptResult}</p>
        </section>

        <Download />

    </div>
  )
}

export default withRouter(AcceptInvite);
