
import axios from "axios";

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000));
  return this;
}

export default class Auth {

  storeAuth() {
    const date = new Date();
    date.addHours(1);
    document.cookie = `authData=${JSON.stringify(this.authData)}; expires=${date}`;
  }

  loadAuth() {
    var decodedCookie = decodeURIComponent(document.cookie);
    var result = decodedCookie.match(new RegExp('authData=([^;]+)'));

    if (result === null) {
      this.authData = null;
      return;
    }
    this.authData = JSON.parse(result[1]);
  }

  async loadSubscriptions() {
    try {
      const gt =  axios.get(`${process.env.REACT_APP_SERVICES_API_BASE}/users/${this.authData.sub}/subscriptions/`, 
      { 
        headers: { 
          'StoreId': `${process.env.REACT_APP_STOREID}`, 
          'Authorization': `Bearer ${this.authData.access_token}`
        },
        params: {
          'IsFutureActive': false
        }
      }
    )   
      return gt;
    } catch(err) {
      return null;
    }
  } 

  isAuthenticated() {
    return this.authData !== null && this.authData !== undefined
  }

  logout() {
    document.cookie = "authData=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    this.authData = null;
    sessionStorage.removeItem("sharedCode");
    localStorage.removeItem("grantURL");
  }

}