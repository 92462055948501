import React, { Component, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";
import Profile from "../components/profile";
// import Invite from "../components/invite";
// import CodeValidation from "../components/codevalidation";
import { Translate } from "react-localize-redux";
import premiumTitle from "../assets/img/premium-title.svg";


class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
        subscriptions: []   
    }
    this.loadSubscriptions = this.loadSubscriptions.bind(this);       
  }

  componentDidMount() {
    this.loadSubscriptions();
  }

  async loadSubscriptions() {
    try {
      const subscriptionsData = await this.props.auth.loadSubscriptions();
      this.setState({ subscriptions: subscriptionsData.data })
    }
    catch (err) {
      this.setState({ subscriptions: [] })
    }
  }  

  refreshSubscriptions = () => {
    this.props.auth.loadSubscriptions();
  }

  onClick = () => {
    this.props.auth.logout();
    this.props.history.push("/");
    window.location.reload();
  }

  render() {
    const auth = this.props.auth;
    let authProfile;

    if (!auth.isAuthenticated()) {
      authProfile = <Redirect to="/" />
    } else {
      authProfile = 
        <div>
            <Profile auth={auth.authData} subscriptions={this.state.subscriptions} />
          
            {/* <Invite auth={auth.authData} />    */}
            {/* <CodeValidation onValidate={this.loadSubscriptions} auth={auth} />  */}
          
        </div>
    }

    let currentPlan = "";

    if(this.state.subscriptions.length > 0) {
        let price;
        if( process.env.REACT_APP_YEARLY_TUNNED_PLAN_KEY === this.state.subscriptions[0].MerchantCode ){
            price = "120 GHS"
        }else if(process.env.REACT_APP_MONTHLY_TUNNED_PLAN_KEY === this.state.subscriptions[0].MerchantCode){
            price = "12 GHS"
        }
        currentPlan = <div className="Account__plans">
            <div className="redeem-sidebar">
                <h2 className="h4">Your plan</h2>
                <div className="Account__plan">
                    <div className="Account__plan__desc">
                        <img className="Account__plan__title" src={premiumTitle} alt="premium banner" />
                        <p className="p2">{price}</p>
                    </div>
                    <div className="Account__plan__type">
                        <p className="p2">{ process.env.REACT_APP_YEARLY_TUNNED_PLAN_KEY === this.state.subscriptions[0].MerchantCode ? "Yearly" : "Monthly"}</p>
                    </div>
                </div>
            </div>
        </div>
    }
    return (
      <Fragment>
        <section className="Account" id="content">
            <div className="Account__container">
                <div className="Account__content">
                    <h1 className="Account__title h3">Your account</h1>
                    <div className="Account__account">
                        <div className="Account__profil">
                            {authProfile}
                        </div>
                        {currentPlan}
                    </div>
                    <button className="logoutct btn-edit" onClick={this.onClick}><Translate id="button.logout" /></button>
                </div>
            </div>
        </section>
      </Fragment>
    )
  }
}

export default withRouter(Account);
