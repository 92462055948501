import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

class Error extends Component {
  
  render() {
    return (
      <div className="page-content" id="content">
        <section> 
            <div className="u-text-center">
                <h1><Translate id="title.page-not-found" /></h1>
                <p className="u-text-white"><Translate id="content.page-doesnt-exist" /></p>
                <div className="buttons u-margin-top">
                <Link to="/" className="btn-primary"><Translate id="button.go-home" /></Link>
                </div>
            </div>
        </section>
      </div>
    );
  }
}

export default Error;
